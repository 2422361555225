@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.btnDroxyAI {
    position: fixed;
    width: 50px;
    height: 50px;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
    display: flex !important;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in;
}

.btnDroxyAI i {
    position: absolute;
    color: #fff;
}

.btnDroxyAI i:first-child{
    opacity: 1;
}

.btnDroxyAI i:last-child{
    opacity: 0;
}

.btnDroxyAI:hover {
    transform: scale(1.1);
    transition: all 0.25s ease-out;
}

.btnDroxyAI:not(:hover) {
    transform: scale(1);
    transition: all 0.25s ease-out;
}
    
.modalDroxyAI {
    z-index: 4;
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-rows: 48px 1fr 48px;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    background-color: #fff;
    overflow: hidden;
    transform: scale(0.2);
    transform-origin: bottom right;
    transition: all 0.2s ease-in;

    &__header {
        background-color: $blue-500;

        i, p {
            color: #fff;
        }

        .btn__close {
            cursor: pointer;
            outline: none;
            border: none;

            i {
                color: #fff;
            }
        }
    }

    &__body {
        width: 100%;
        height: 100%;
    }

    &__footer {
        span {
            font-size: 10px;
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            height: fit-content;

            img {
                width: 36px;
                height: auto;
            }
        }
    }
}

.container__chatbot.show__chatbot .modalDroxyAI {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
}

.container__chatbot.show__chatbot .btnDroxyAI {
    transform: rotate(90deg);
}

.container__chatbot.show__chatbot .btnDroxyAI i:first-child {
    opacity: 0;
}

.container__chatbot.show__chatbot .btnDroxyAI i:last-child {
    opacity: 1;
}


@media screen and (min-width: 768px){

    .btnDroxyAI {
        right: 30px;
        bottom: 30px;
    }

    .modalDroxyAI {
        width: 350px;
        height: 500px;
        right: 30px;
        bottom: 90px;
    }

}