@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.btn__locationBack:hover {
    i, span {
        color: $blue-500;
    }
}

.sidebarOpen {
    position: relative;
    display: grid;
    grid-template-columns: 240px auto;
    transition: grid-template-columns 0.1s;
    height: 100vh;

    &.active {
        grid-template-columns: 80px auto;
    }

    .main {
        display: grid;
        grid-template-rows: 80px auto;
        width: 100vw;
        height: 100%;
        overflow: scroll;

        &__outlet__container {
            padding: 20px;
        }

    }
}

@media screen and (min-width: 768px) {
    .sidebarOpen {
        .main {
            display: grid;
            grid-template-rows: 80px auto;
            width: 100%;
            height: 100vh;
            overflow: auto;

            &__outlet__container {
                overflow-y: scroll;
                width: 100%;
                margin: 0;
                padding: 40px;
            }

            &__outlet__container::-webkit-scrollbar {
                width: 10px;
            }
    
            &__outlet__container::-webkit-scrollbar-track {
                background-color: rgba($color: $gray-300, $alpha: 0);
            }
    
            &__outlet__container::-webkit-scrollbar-thumb {
                background-color: $gray-500;
                border-radius: 5px;
            }
        }
    }
}