@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.img__error {
    width: 200px;
    max-width: 100%;
}

.listaAreas__okr--container {
    height: calc(100% - 48px);

    .listaAreas__okr {
        height: calc(100% - 62px);
    }
}

@media screen and (min-width: 768px) {
    .listaAreas__okr--container {
        .listaAreas__okr {
            overflow-y: scroll;
            padding-right: 30px;
        }

        .listaAreas__okr::-webkit-scrollbar {
            width: 10px;
        }

        .listaAreas__okr::-webkit-scrollbar-track {
            background-color: rgba($color: $gray-300, $alpha: 0);
        }

        .listaAreas__okr::-webkit-scrollbar-thumb {
            background-color: $gray-500;
            border-radius: 5px;
        }
    }
}