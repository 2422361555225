@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';
 
.KRDetalle {
    width: calc(100vw - 40px);
    height: calc(100% - 40px);

    &__main {
        .btn__edit, .btn__delete {
            width: 32px;
            height: 32px;
            padding: 0;
        }

        .subtitulo {
            font-weight: 500;
        }

        &__info {
            .btn__DeleteActividad, .btn__EditarActividad {
                width: 24px;
                height: 24px;

                i {
                    font-size: 14px;
                }
            }

            &__medible {
                .input__fake {
                    width: 160px;
                    font-size: 14px;
                }
    
                &__ingresos {
                    .ingresos__main--empty {
                        border: 2px dashed $blue-500;
                    }
    
                    .ingresos__main {
                        .each__ingreso {
                            p {
                                width: auto;
                            }
    
                            .icon__ingresoOk {
                                width: 24px;
                                height: 24px;
            
                                i {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
    
                    .addIngreso__container {
                        .input__date--addIngreso {
                            width: 140px;
                        }
        
                        .input__number--addIngreso {
                            width: 160px;
                        }
        
                        .btn__addIngreso {
                            width: 24px;
                            height: 24px;
        
                            i {
                                font-size: 14px;
                            }
                        }
    
                        .ingreso--error {
                            font-size: 12px;
                        }
                    }
                }   
            }
    
            &__nomedible {
                .addHito__container {
                    label {
                        font-size: 14px;
                    }

                    .input__text--addHito {
                        width: 300px;
                    }

                    .input__date--addHito {
                        width: 140px;
                    }

                    .btn__addHito {
                        width: 24px;
                        height: 24px;
    
                        i {
                            font-size: 14px;
                        }
                    }
                }

                .hito--error {
                    font-size: 12px;
                }

                .hitos__main--empty {
                    border: 2px dashed $blue-500;
                }

                .hitos__main {
                    .tableHitos__custom {
                        width: calc(100vw - 40px);
                        overflow-x: scroll;
                        display: flex;
                        flex-direction: column;
                        font-size: 14px;

                        &__header {
                            display: flex;
                            flex-direction: row;
                            border-bottom: 1px solid #dee2e6;
                        }

                        &__body {
                            display: flex;
                            flex-direction: column;
                        }

                        &__row {
                            height: 50px;
                            border-bottom: 1px solid #dee2e6;
                        }

                        &__cell {
                            white-space: nowrap;
                            padding: 6px 10px;
                        }

                        &__cell--title {
                            color: #0d6efd;
                        }

                        .cell__check {
                            width: 40px;
                        }

                        .cell__actividad {
                            width: 400px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }

        

        &__extras {
            .avatar__aside {
                width: 24px;
                height: 24px;
            }

            .badge--baja {
                background-color: $blue-500 !important;
            }

            .badge--media {
                background-color: $blue-600 !important;
            }

            .badge--alta {
                background-color: $blue-700 !important;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .KRDetalle {
        width: auto;

        &__main {
            &__info {
                &__medible {
                    &__ingresos {
                        .ingresos__main {
                            .each__ingreso {
                                p {
                                    width: 140px;
                                }
                            }
                        }
                    }
                }

                &__nomedible {
                    .addHito__container {
                        padding-left: 50px;
                    }

                    .hitos__main {
                        .tableHitos__custom {
                            width: fit-content;
                            overflow-x: visible;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .KRDetalle {
        &__main {
            display: grid;
            grid-template-columns: auto 240px;
            // grid-template-rows: max-content;
            column-gap: 30px;
        }
    }
}