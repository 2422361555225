@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.swiper-container {
    position: relative;
    width: calc(100% - 60px);
    // max-width: 800px;
    margin: auto;
}

.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    // top: 50%;
    // transform: translateY(-50%);
    width: 20px;
    height: 24px;
    // background-color: rgba(0, 0, 0, 0.5);
    color: $blue-500;
    border-radius: 50%;
    z-index: 10;
    cursor: pointer;
}

.swiper-button-prev {
    left: -30px;
}
  
  .swiper-button-next {
    right: -30px;
}

.swiper-button-next:after, .swiper-button-prev:after {
    font-size: 24px;
}

.swiper-pagination-persnl, .swiper-pagination-area {
    position: relative;
    bottom: -20px !important;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
.swiper-pagination-persnl-bullet, .swiper-pagination-area-bullet {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    cursor: pointer;
}
  
.swiper-pagination-persnl-bullet-active, .swiper-pagination-area-bullet-active {
    background-color: rgba(0, 0, 0, 0.7);
}

// .items__container {
//     display: grid;
//     grid-template-columns: 1fr;
//     grid-template-rows: max-content;
//     column-gap: 20px;
//     row-gap: 20px;
// }

@media screen and (min-width: 768px) {
    // .items__container {
    //     position: relative;
    //     grid-template-columns: 1fr 1fr;
    // }
}

@media screen and (min-width: 992px) {
    // .items__container {
    //     position: relative;
    //     grid-template-columns: 1fr 1fr 1fr 1fr;
    // }
}