@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.areas__home--empty, .areas__home--error {
    height: calc(100% - 192px);

    img {
        width: 200px;
        max-width: 100%;
    }
}

.areas__home--empty__interno, .areas__home--error__interno {
    height: calc(100% - 48px);

    img {
        width: 200px;
        max-width: 100%;
    }
}

.loading__home {
    width: 100%;
    padding-top: 40px;

    div {
        margin-bottom: 10px;
        svg g g circle[stroke] {
            stroke: $blue-500;
        }
    }

    p {
        color: $blue-500
    }
}

.home {
    width: calc(100vw - 40px);
    height: 100%;

    &__container {
        display: flex;
        flex-direction: column;
        // display: grid;
        // grid-template-columns: 1fr;
        // grid-template-rows: 1fr auto;
        // grid-template-areas: "a" "b";
        // row-gap: 20px;
    }
    
    &__bienvenida {
        // grid-area: a;
        padding: 20px 20px 0 20px;
        background: linear-gradient(135deg, $blue-500 0%, $blue-300 100%);
    
        &__texto {
            h2 {
                span {
                    font-size: 16px;
                }
            }
        }
    
        &__img {
            img {
                width: 100%;
            }
        }
    }

    &__dashboards {
        overflow-y: scroll;
        // grid-area: b;

        .img__error {
            width: 200px;
            max-width: 100%;
        }

        .table__custom {
            width: 100%;
            display: flex;
            flex-direction: column;
            font-size: 14px;

            &__header {
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid $gray-300;
            }
        
            &__body {
                display: flex;
                flex-direction: column;
            }
        
            &__row {
                height: 50px;
                display: flex;
                flex-direction: row;
                align-items: center;
                border-bottom: 1px solid $gray-300;
                cursor: pointer;
            }

            &__row:nth-last-child(odd){
                background-color: $gray-100;
            }

            &__row:nth-last-child(even){
                background-color: $gray-200;
            }

            &__row:hover {
                background-color: $blue-500;
                color: #fff !important;
            }

            &__cell {
                white-space: nowrap;
                padding: 6px 10px;
        
                &--title {
                    color: $blue-500;
                }
            }

            .cell__id {
                width: 40px;
            }

            .cell__name {
                width: 200px;
            }
        }
    }

    &__consultoras {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        &__consultora {
            cursor: pointer;
            background-color: #fff;
            padding: 10px !important;
            width: calc(50% - 5px);
            height: 80px;
    
            h4 {
                color: $blue-500;
            }
        }

        &__consultora:hover {
            background-color: $blue-500 !important;
    
            h4 {
                color: #fff;
            }
        }

        &__consultora--slider {
            cursor: pointer;
            background-color: #fff;
            padding: 10px !important;
            width: 100%;
            height: 80px;
    
            h4 {
                color: $blue-500;
            }
        }

        &__consultora--slider:hover {
            background-color: $blue-500 !important;
    
            h4 {
                color: #fff;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .home {
        width: 100%;
        
        &__bienvenida {
            height: 120px;
            // height: fit-content;
            position: relative;
            padding: 30px;
    
            &__img {
                position: absolute;
                right: 0;
                bottom: 0;
                padding-right: 30px;

                img {
                    // max-width: 260px;
                    width: 260px;
                }
            }
        }

        &__dashboards {
            height: calc(100% - 192px);
        }

        &__consultoras {
            &__consultora {
                padding: 20px !important;
                height: 100px;
            }

            &__consultora--slider {
                padding: 20px !important;
                height: 100px;
            }
        }
    }
}

@media screen and (min-width: 992px){
    .home {
        &__consultoras {
            &__consultora {
                width: calc(20% - 10px);
            }
        }
    }
}