@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.areas {
    height: calc(100% - 40px);

    &__main {
        width: calc(100vw - 40px);
        height: calc(100% - 48px);

        &__container {
            height: calc(100% - 62px);
        }
    }
}

@media screen and (min-width: 768px) {
    .areas {
        &__main {
            width: 100%;

            &__container {
                overflow-y: scroll;
                padding-right: 30px;
            }

            &__container::-webkit-scrollbar {
                width: 10px;
            }

            &__container::-webkit-scrollbar-track {
                background-color: rgba($color: $gray-300, $alpha: 0);
            }

            &__container::-webkit-scrollbar-thumb {
                background-color: $gray-500;
                border-radius: 5px;
            }
        }
    }
}