@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

// CSS de la tabla
.details-enter {
    opacity: 0;
}
.details-enter-active {
    opacity: 1;
    transition: opacity 100ms;
}
.details-exit {
    opacity: 1;
}
.details-exit-active {
    opacity: 0;
    transition: opacity 100ms;
}
// .details-enter {
//     height: 0;
//     overflow: hidden;
// }

// .details-enter-active {
//     height: 100px; /* Ajusta según el contenido esperado */
//     transition: height 0.3s ease;
// }

// .details-exit {
//     height: 100px; /* Ajusta según el contenido esperado */
//     overflow: hidden;
// }

// .details-exit-active {
//     height: 0;
//     transition: height 0.3s ease;
// }

.icon__lista__task {
    width: 40px;
    height: 50px;
}

.icon__lista__muestra {
    width: 80px;
    height: 50px;
    padding-left: 20px;

    img {
        width: 60px;
        height: 50px;
    }
}

.modal__duplicate__ciclo {
    .modal-body {
        .form__duplicate__ciclo {
            label {
                font-size: 14px;
            }
            
            h4 {
                color: $blue-500;
            }
        }
        
    }
}

// Custom table
.table__customAU {
    // width: 1940px;
    width: 1740px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    // width: max-content;

    &__header {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $gray-300;
    }

    &__body {
        display: flex;
        flex-direction: column;
    }

    &__row {
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid $gray-300;
    }

    &__row--btnadd {
        // height: 50px;
        // margin-left: 40px;
        padding: 6px 10px;

        .btn__file {
            width: auto;
        }

        .btn__addMuestra {
            width: max-content;
        }
    }

    .bgblue {
        background-color: #e6efff;
    }

    .bggray300 {
        background-color: $gray-300;
    }

    .bggray200 {
        background-color: $gray-200;
    }

    .bggray100 {
        background-color: $gray-100;
    }

    .light {
        background-color: $light;
    }

    &__cell {
        white-space: nowrap;
        padding: 6px 10px;

        &--title {
            color: $blue-500;
        }

        .btn__edit--icon {
            color: $success
        }

        .btn__delete--icon {
            color: $danger
        }
    }

    
    .btn__ico {
        width: 20px;
    }

    .btn__ico--g {
        width: 40px;
    }


    // Cell sizes
    .cell__dropdown {
        width: 40px;
    }

    .cell__buttons {
        width: 180px; 
    }

    .cell__buttons--task {
        width: 180px; 
        margin-left: 40px;
    }

    .cell__orden {
        width: 70px;
    }

    .cell__nombre {
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cell__prioridad {
        width: 90px;
    }

    .cell__estado {
        width: 120px;
    }

    .cell__progreso {
        width: 140px;
    }

    .cell__horas {
        width: 120px;
    }

    .cell__notas {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cell__mail {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cell__date {
        width: 120px;
    }

}

.loading__tareas {
    width: 100%;
    height: 100%;

    div {
        margin-bottom: 10px;
        svg g g circle[stroke] {
            stroke: $blue-500;
        }
    }

    p {
        color: $blue-500
    }
}

.ciclos__btn {
    height: 40px;
    width: fit-content;

    i {
        line-height: normal;
        font-size: 24px;
        color: $blue-500;
    }

    span {
        font-size: 14px;
        color: $blue-500;
    }
}

.loading__subtareas {
    padding: 20px;

    div {
        svg g g circle[stroke] {
            stroke: $blue-500;
        }
    }

    p {
        color: $blue-500
    }
}

.tareas {
    height: 100%;

    &__error {
        width: 100%;
        // height: calc(100% - 48px);

        img {
            width: 200px;
            max-width: 100%;
        }

        h2 {
            color: $blue-500
        }
    }

    &__inicio {
        width: 100%;
        height: 100%;

        img {
            width: 150px;
            max-width: 100%;
        }
    
        h2 {
            color: $blue-500
        }
    }

    &__header {
        // &__title {
        //     cursor: pointer;
        // }
        
        h3 {
            i {
                color: $blue-500;
            }
        }

        .btn__edit, .btn__delete, .btn__duplicate {
            width: 32px;
            height: 32px;
            padding: 0;
        }

        .btn__addTarea {
            width: 170px;
            height: 40px;
        }
    }

    &--empty__main {
        border: 2px dashed $blue-500;
        height: 100%;

        h2 {
            color: $blue-500
        }

        &__btn {
            height: 40px;
            background-color: $blue-500 !important;
            color: #fff !important;
        }
    }

    &__main {
        height: 100%;
        position: relative;
        overflow-x: scroll;

        .doughnut--empty {
            width: 60px;
            // height: 20px;
        }
        
        .btn__area {
            font-size: 12px;
        }

        &__graficas {
            height: max-content;
            gap: 20px;
            transition: height 0.1s;

            &__doughnut {
                width: 100%;
                padding: 16px 20px;
                margin-bottom: 20px;

                &__info {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    
                    &__textos {
                        h4 {
                            color: $blue-500;
                        }

                        p {
                            font-size: 14px;
                        }

                        p:first-child {
                            color: $blue-500;
                        }
                    }

                    &__chart {
                        width: 100px;
                        height: 100px;
                    }
                }
            }
            &__doughnut:last-child {
                margin-bottom: 0;
            }
        }

        &__graficas--close {
            height: 0px;
            transition: height 0.1s;
        }

        &__tabla {
            // overflow-x: scroll;
            width: calc(100vw - 40px);

            .table {
                table-layout: auto;
                width: 100%;

                thead {
                    tr {
                        th {
                            white-space: nowrap;
                            overflow:hidden;
                            color: $blue-500;
                            padding: 5px 20px;
                        }
                    }
                }

                &__tbody {
                    // position: relative;

                    // .btn__showsub {
                    //     position: absolute;
                    //     height: 32px;
                    //     border-radius: 0 0 16px 16px;
                    // }

                    // tr {
                    //     position: relative;
                    // }

                    &__tarea {
                        cursor: pointer;
                    }

                    // &__tarea:hover .active{
                    //     display: flex;
                    // }

                    td {
                        padding: 5px 20px;
                        height: 50px;
                    }

                    &__nombre {
                        max-width: 300px;
                        text-overflow: ellipsis;
                    }

                    &__nombre, &__fechaInicial, &__fechaFinal, &__equipo, &__notas {
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    &__notas {
                        max-width: 300px;
                        text-overflow: ellipsis;
                    }
                    &__prioridad {
                        span {
                            padding: 6px 12px;
                        }

                        &--baja {
                            background-color: $blue-500 !important;
                        }

                        &--media {
                            background-color: $blue-600 !important;
                        }

                        &--alta {
                            background-color: $blue-700 !important;
                        }
                    }

                    &__estado {
                        span {
                            padding: 6px 12px;
                        }

                        &--pendiente {
                            background-color: $gray-500 !important;
                        }

                        &--proceso {
                            background-color: $warning !important;
                        }

                        &--completada {
                            background-color: $success !important;
                        }

                        &--espera {
                            background-color: $purple-400 !important;
                        }

                        &--cancelada {
                            background-color: $danger !important;
                        }

                        &--bloqueada {
                            background-color: $black !important;
                        }
                    }

                    &__progreso {

                        &__bar {
                            width: 120px;
                        }
                        
                    }

                    &__buttons {
                        // display: none;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        // position: absolute;
                        // left: 0;
                        height: 50px;

                        button {
                            width: 32px;
                            height: 32px;
                            padding: 0;
                        }
                    } 

                    .btn__subtarea {
                        background-color: $blue-500;
                        color: #fff;
                        margin: 10px 20px 20px 20px;
                        width: 180px;
                    }

                    .btn__subtarea:hover {
                        background-color: #0b5ed7;
                    }
                }
            }
        }
    }

    &__main::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &__main::-webkit-scrollbar-track {
        background-color: rgba($color: $gray-300, $alpha: 0);
    }

    &__main::-webkit-scrollbar-thumb {
        background-color: $gray-500;
        border-radius: 5px;
    }
}

@media screen and (min-width: 768px) {
    .loading__tareas {
        height: calc(100% - 40px);
    }

    .tareas__container {
        width: calc(100% - 230px);
    }

    .tareas__container--close {
        width: 100%;
        transition: width 0.1s;
    }

    .tareas {
        width: 100%;
        height: calc(100% - 40px);

        &__error {
            height: 100%;
        }

        &__inicio {
            width: 100%;

            img {
                width: 200px;
            }
        }

        &__main {
            // height: calc(100% - 48px);

            &__graficas {
                gap: 20px;
    
                &__doughnut {
                    width: 33.3%;
                    margin-bottom: 0;
                }
            }

            &__tabla {
                width: 100%;
                height: 100%;
            }
        }
    } 
}